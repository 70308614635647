import React, {useEffect} from "react";
import './style.scss';
import {Link, useLocation} from "react-router-dom";
import {adminMenuItems} from "../../../constants/menu";
import Image from "../../../components/Image";
import Logo from "../../../assets/images/Logo-aet.png";



const SideBar:React.FC = ()=>{
    const location = useLocation();

    useEffect(() => {
        console.log(location.pathname)
    }, []);

    return (
        <aside className="sidenav ">
            <div className="sidenav__close-icon">
                <i className="fas fa-times"></i>
            </div>
            <div className="logo">
                <Image src={Logo} />
                <p>
                    ASSOCIATION <br/>
                    DES AET
                </p>
            </div>
            <ul className="sidenav__list karla-section ">
                {adminMenuItems.map((item, index) => (
                    <li className="" key={index}>
                        <Link to={item.url} className={`sidenav__list-item ${location.pathname === item.url ? 'link-active' : ''}`}>
                            <item.icon className="sidenav__icon" size={20} />
                            <span className="sidenav__text">{item.title}</span>
                        </Link>
                    </li>
                ))}
            </ul>
        </aside>
    )
}

export default SideBar
