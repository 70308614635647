import React from 'react';
// import './Avatar.css';
import {AvatarProps} from "../../types"; // Import CSS file for styling




const Avatar: React.FC<AvatarProps> = ({ src, alt, size = 50 }) => {
    return (
        <img
            src={src}
            alt={alt}
            className="avatar"
            style={{ width: size, height: size, borderRadius: `${size}px` }}
        />
    );
};

export default Avatar;
