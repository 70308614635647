import {Outlet} from "react-router-dom";
import React from "react";
import './style.scss'
const EditorLayout:React.FC = () => {
  return (
      <>
          <Outlet/>
      </>
  )
}
export default EditorLayout
