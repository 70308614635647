import UserFace from "#images/userdefault.jpg";
import React, {useEffect, useRef, useState} from "react";
import './style.css';
import {IoIosArrowDown} from "react-icons/io";
import Avatar from "../../../components/Avatar";
import {useOutsideClick} from "../../../hooks";
import {SlBell} from "react-icons/sl";
import {VscBellDot} from "react-icons/vsc";

const HeaderNotificationBox: React.FC = () => {
    const [open, setOpen] = useState(false)
    const userInfoBoxRef = useOutsideClick(() => {
        open && toogle()
    });




    const toogle = () => {
        setOpen(!open)
        console.log(open)
    }
    return (
        <div className="notificationbox">
            <div className="top" onClick={toogle}>
                <VscBellDot />
            </div>
            {open && <div className="backdrop" />}

            {
                <div ref={userInfoBoxRef} className={`notification-modal ${open ? "show" : ""}`} >
                    <div className="top no-hover">
                        Notification
                    </div>
                    <div className="notifications">
                        <ul>
                            <li>New notifification</li>
                            <li>New notifification</li>
                            <li>New notifification</li>
                            <li>New notifification</li>
                            <li>New notifification</li>
                            <li>New notifification</li>
                            <li>New notifification</li>
                        </ul>
                    </div>
                    <div className="clear-button-container">
                        <b>clear</b>
                    </div>
                </div>

            }


        </div>
    )
}

export default HeaderNotificationBox
