import UserFace from "../../../assets/images/userdefault.jpg";
import React, {useEffect, useRef, useState} from "react";
import './style.css';
import {IoIosArrowDown} from "react-icons/io";
import Avatar from "../../../components/Avatar";
import {useOutsideClick} from "../../../hooks";
import {useLogin, useLogout} from "../../../services/api/hooks/httpHooks";
import {Link} from "react-router-dom";
import {LocalStoredUser} from "../../../types/api";
import {BASE_URL} from "../../../config";

const AdminUserBox: React.FC = () => {
    const [open, setOpen] = useState(false)
    const [logout, { loading, error, isLoggedOut }] = useLogout();
    const [login, { isConnected, loading:loadinLogin, userData }] = useLogin<LocalStoredUser>('auth/login');


    useEffect(() => {
        if(userData) console.log(userData)
    }, [userData]);
    const handleLogout = async () => {
        try {
            await logout();
            // Optionally, you can perform additional actions after logout (e.g., redirect to login page)
        } catch (error) {
            // Handle any errors that occurred during logout
            console.error('Logout error:', error);
        }
    };
    const userInfoBoxRef = useOutsideClick(() => {
        open && toogle()
    });


    const toogle = () => {
        setOpen(!open)
        console.log(open)
    }
    return (
        <>
            {
                isConnected && userData &&
                <div className="userbox">
                    <div className="top" onClick={toogle}>
                        <Avatar src={userData.image !== '' ? `${BASE_URL}/${userData.image}` : UserFace}/>
                        <ul className="userbox-user-info">
                            <li className="name">{userData.name}</li>
                            <li className="role">
                                {
                                    userData.roles.map((role, idx: number)=>{
                                        if(idx===0) return role.display_name
                                        return ' - '+role.display_name
                                    })
                                }
                            </li>
                        </ul>
                        <IoIosArrowDown style={{marginLeft: "1em"}}/>
                    </div>
                    {open && <div className="backdrop" />}

                    {
                        <div ref={userInfoBoxRef} className={`user-modal ${open ? "show" : ""}`} >
                            <div className="top no-hover">
                                <Avatar src={userData.image !== '' ? `${BASE_URL}/${userData.image}` : UserFace}/>
                                <ul className="userbox-user-info">
                                    <li className="name">{userData.name}</li>
                                    <li className="role">
                                        {
                                            userData.roles.map((role, idx: number)=>{
                                                if(idx===0) return role.display_name
                                                return ' - '+role.display_name
                                            })
                                        }
                                    </li>
                                </ul>
                            </div>
                            <div className="options">
                                <ul>
                                    <li>My profile</li>
                                    <li>Messages</li>
                                    <li>Account settings</li>
                                </ul>
                            </div>
                            <div className="signout-button-container" >
                                <Link to="/login" onClick={handleLogout}>
                                    <b>Signout</b>
                                </Link>
                            </div>
                        </div>

                    }
                </div>
            }
        </>
    )
}

export default AdminUserBox
