import React from 'react';
import {SeparatorProps} from "../../types";



const Separator: React.FC<SeparatorProps> = ({ className = '', onClick, style }) => {
    // Default button styles for width and height (you can adjust these values)
    const defaultStyles: React.CSSProperties = {
        backgroundColor: '#FEAF00',
        height:"4px",
        width:"60px",
        cursor: 'pointer',
        marginLeft:"1em"
    };

    const separatorStyles: React.CSSProperties = {
        ...defaultStyles, // Apply default styles
        ...style, // Apply any custom styles passed via props
    };

    return (
        <div className={`separator ${className}`} style={separatorStyles} onClick={onClick}>
        </div>
    );
};

export default Separator;
