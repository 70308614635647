import {Outlet, ScrollRestoration, useLocation} from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import React, {useLayoutEffect} from "react";


const PageLayout = ()=>{


    return (
        <>

            <Header title={"Home"}/>
            <main id={"main-app-content"}><Outlet/></main>
            <ScrollRestoration />
            <Footer copyright="AAET-BF 2024"/>
        </>
    )
}

export default PageLayout;
