import UserFace from "../../../assets/images/userdefault.jpg";
import React, {useEffect, useState} from "react";
import './style.css';
import {IoIosArrowDown} from "react-icons/io";
import Avatar from "../../../components/Avatar";
import {useOutsideClick} from "../../../hooks";
import {useLogin, useLogout} from "../../../services/api/hooks/httpHooks";
import {Link} from "react-router-dom";
import {LocalStoredUser} from "../../../types/api";
import {FaSearch, FaShoppingBag, FaUser} from "react-icons/fa";
import {medium} from "../../../styles/icons";
import {BASE_URL} from "../../../config";

const AETUserBox: React.FC = () => {
    const [open, setOpen] = useState(false)
    const [logout, { loading, error, isLoggedOut }] = useLogout();
    const [login, { isConnected, loading:loadinLogin, userData }] = useLogin<LocalStoredUser>('auth/login');
    const scrollToSection = () => {
        const section = document.getElementById('new-aet');
        if (section) {
            section.scrollIntoView({ behavior: 'smooth' });
        }
    };

    useEffect(() => {
        if(userData) console.log(userData)
    }, [userData]);
    const handleLogout = async () => {
        try {
            await logout();
            // Optionally, you can perform additional actions after logout (e.g., redirect to login page)
        } catch (error) {
            // Handle any errors that occurred during logout
            console.error('Logout error:', error);
        }
    };
    const userInfoBoxRef = useOutsideClick(() => {
        open && toogle()
    });


    const toogle = () => {
        setOpen(!open)
        console.log(open)
    }
    return (
        <div className="aet-userbox-container flex flex-row items-center">
            {
                isConnected && userData &&
                <div className="aet-userbox">

                    <div className="top" onClick={toogle}>
                        <Avatar src={userData.image !== '' ? `${BASE_URL}/${userData.image}` : UserFace} size={30}/>
                        <ul className="aet-userbox-user-info mobile-hidden flex flex-col !gap-1">
                            <li className="name">{userData.name}</li>
                            <li className="role">
                                {
                                    userData.roles.length > 0 && userData.roles[userData.roles.length-1]?.display_name
                                }
                            </li>
                        </ul>
                        <IoIosArrowDown className="aet-userbox__arrow" style={{marginLeft: "1em"}}/>
                    </div>
                    {open && <div className="backdrop" />}
                    {
                        <div ref={userInfoBoxRef} className={`user-modal ${open ? "show" : ""}`} >
                            <div className="top no-hover">
                                <Avatar src={userData.image !== '' ? `${BASE_URL}/${userData.image}` : UserFace} size={30}/>
                                <ul className="aet-userbox-user-info !gap-1" style={{color:'black'}}>
                                    <li className="name">{userData.name}</li>
                                    {
                                        userData.roles.map((role, idx: number)=>{
                                            return <li className="role">{role.display_name}</li>
                                        })
                                    }
                                    
                                </ul>
                            </div>
                            <div className="options">
                                <ul className="flex margin-0 !gap-1">
                                    <li className="cursor-pointer">Mon profile</li>
                                    <li className="cursor-pointer">Parametre</li>
                                    {
                                        userData && userData.roles && userData.roles.some(role => ['admin', 'super-admin'].includes(role.name)) &&
                                        <li className="go-to-admin cursor-pointer">
                                            <Link to='/admin' className="hide-mobile-tablete">
                                                Aller vers la page admin
                                            </Link>
                                        </li>
                                    }
                                    <li className="cursor-pointer">
                                        <a href="/store">
                                            Boutique aet
                                        </a>
                                    </li>

                                    <li className="cursor-pointer">
                                        <Link to="/find-aet">
                                            Rechercher un AET
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                            <div className="signout-button-container text-red-500 mt-1" >
                                <Link to="/login" onClick={handleLogout}>
                                    <b>Signout</b>
                                </Link>
                            </div>
                        </div>

                    }
                </div>
            }
            {
                !isConnected &&
                <div className="aet-userbox-container-guest_link">
                    <Link to="/find-aet" className="login">
                            <FaSearch style={medium}/>
                    </Link>
                    <Link to="login" className="login">
                        <FaUser style={medium}/>
                    </Link>
                    <li className="cart">
                        <a href="/store">
                            <FaShoppingBag style={medium}/>
                        </a>
                    </li>
                    {/*<li className="join">*/}
                    {/*    <Link to="/home#new-aet" onClick={scrollToSection}>*/}
                    {/*        <Button style={{maxWidth:"220px",maxHeight:"50px",borderRadius:"7px",backgroundColor:"#FFFFFF",color:"#4169E1",fontSize:"16px",fontWeight:"700",textTransform:"uppercase"}}>*/}
                    {/*            Adherer*/}
                    {/*        </Button>*/}
                    {/*    </Link>*/}
                    {/*</li>*/}
                </div>
            }
        </div>
    )
}

export default AETUserBox
