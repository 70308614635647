import React from "react";
import './style.css';
import { Outlet } from 'react-router-dom';
import Header from "./Header";
import Sidebar from "./Sidebar";

const AdminLayout : React.FC = ()=>{
    return (
        <div className="grid-container">
            <div className="menu-icon">
                <i className="fas fa-bars"></i>
            </div>
            <Header/>
            <Sidebar/>
            <main className="main">
                <Outlet />
            </main>
            <footer className="footer">
                <div className="footer__copyright">&copy; 2018 MTH</div>
                <div className="footer__signature">Made with love by pure genius</div>
            </footer>
        </div>
    )
}
export default AdminLayout
