import React from "react";
import './style.scss'
import Image from "../../../components/Image";
import Logo from '../../../assets/images/Logo-aet.png';
import Button from "../../../components/Button";
import {FaPlus} from "react-icons/fa";
import AdminUserBox from "../../../content/Users/AdminUserBox";
import HeaderNotificationBox from "../../../content/Notifications/HeaderNotificationBox";
import {Link} from "react-router-dom";
const Header:React.FC = ()=>{
    return (
        <header className="header">
            <div className="left-container">
                <div>
                    <strong>
                        Hello, 
                    </strong>
                </div>
                <div>
                    Have a nice day
                </div>
            </div>
            <div className="right-container">
                <div className="notification">
                    <HeaderNotificationBox/>
                </div>
                <div className="user">
                    <AdminUserBox/>
                </div>
            </div>
        </header>
    )
}

export default Header
