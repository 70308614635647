import {MdHighQuality, MdKeyboardCommandKey, MdVideoLibrary} from "react-icons/md";
import {LuNewspaper, LuUsers} from "react-icons/lu";
import {FaCalendarAlt, FaInfo} from "react-icons/fa";
import {IoCalendarOutline, IoChatboxEllipsesOutline, IoDocumentTextOutline} from "react-icons/io5";
import {TbPhotoSquareRounded} from "react-icons/tb";
import {LiaUserAstronautSolid} from "react-icons/lia";
import {CiChat1, CiShop} from "react-icons/ci";
import {FiShoppingCart} from "react-icons/fi";


export type AdminMenuItem = {
    url: string;
    title: string;
    icon: React.ElementType;
}


export const adminMenuItems: AdminMenuItem[] = [
    {
        url: '/admin/dashboard',
        title: 'Dashboard',
        icon: MdKeyboardCommandKey
    },
    {
        url: '/admin/users',
        title: 'Les administrateurs',
        icon: LuUsers
    },
    {
        url: '/admin/blog',
        title: 'Blog',
        icon: IoDocumentTextOutline
    },
    {
        url: '/admin/agenda',
        title: 'Evenements',
        icon: IoCalendarOutline
    },
    {
        url: '/admin/aet-news',
        title: 'Fait divers',
        icon: LuNewspaper
    },
    // {
    //     url: '/admin/photo-gallery',
    //     title: 'Galeries photos',
    //     icon: TbPhotoSquareRounded
    // },
    {
        url: '/admin/store',
        title: 'Boutique des AET',
        icon: CiShop
    },
    {
        url: '/admin/store/orders',
        title: 'Les commandes',
        icon: FiShoppingCart
    },
    {
        url: '/admin/post-request',
        title: 'Demandes de publicatios',
        icon: LiaUserAstronautSolid
    },
    // {
    //     url: '/admin/chat',
    //     title: 'Message',
    //     icon: IoChatboxEllipsesOutline
    // }

];


