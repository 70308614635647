import React from 'react';
import {HeaderProps} from "../../../types";
import './style.css';
import {FaFacebookF, FaInstagram, FaLinkedinIn, FaTwitter} from "react-icons/fa6";
import {medium, small} from "../../../styles/icons";
import aetLogo from '../../../assets/images/Logo-aet.png';
import Image from "../../../components/Image";
import {FaShoppingBag} from "react-icons/fa";
import { FaUser } from 'react-icons/fa';
import SvgIcon from "../../../components/SvgIcon";
import {ReactComponent as SvgBurgerMenu} from "../../../assets/svg/BurgerMenu.svg";
import HorizontalNavBar from "../../../components/HorizontalNavBar";
import SpinningImage from "../../../components/SpinningImage";
import BurgerSidebar from "../../../components/BurgerSidebar";
import {mainAppLinks} from "../../../constants/links";
import {Link} from "react-router-dom";
import AETUserBox from "../../../content/Users/AETUserBox";


const Header: React.FC<HeaderProps> = ({ title="Default title" }) => {

  return (
      <header>
          <div className="top-bar">
              <div className="top-bar-box top-bar_left">
                  <a href="https://www.facebook.com">
                      <FaFacebookF style={small}/>
                  </a>
                  <a href="https://www.instagram.com">
                      <FaInstagram style={small}/>
                  </a>
                  <a href="https://twitter.com">
                      <FaTwitter style={small}/>
                  </a>
                  <a href="https://linkedin.com">
                      <FaLinkedinIn style={small}/>
                  </a>
                  <a href="contacter" className="nous-contacter">
                      <b style={{margin:"0 1em"}}>|</b>   Nous contacter</a>
              </div>
              <div className="top-bar-box top-bar_center">
                  <>
                      <Image src={aetLogo} alt={"Logo aet"} style={{width: "30px", height: "30px"}}/>
                  </>
              </div>
              <div className="top-bar-box top-bar_right">
                  <AETUserBox/>
                  <div className="burger-icon">
                      <BurgerSidebar menuItems={mainAppLinks} >
                          <SvgIcon icon={SvgBurgerMenu} width={medium.fontSize} height={medium.fontSize}/>
                      </BurgerSidebar>
                  </div>
              </div>
          </div>
          <div className="nav-bar-lg">
            <HorizontalNavBar menuItems={mainAppLinks}/>
          </div>
      </header>
  );
};



export default Header;
