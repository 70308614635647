import React from 'react';
import './App.scss';
import {createBrowserRouter, RouterProvider} from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import router from "./router";


function App() {
    const content = createBrowserRouter(router);
    return (
        <>
            <RouterProvider router={content} />
            <Toaster />
        </>
    );
}

export default App;
