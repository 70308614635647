import { useEffect, useState } from 'react';
import { useCustomApiClient } from '../CustomApiClientProvider';
import {
    HttpDeleteHookReturnType,
    HttpGetHookReturnType, HttpPatchHookReturnType,
    HttpPostHookReturnType,
    HttpPutHookReturnType, LoginReturnType, LogoutReturnType,
    RequestOptions, UserCredential
} from "../types";
import toast from "react-hot-toast";

export function useHttpGet<T>(url: string, options: RequestOptions = {}): {
    metadata: any;
    data: T | null;
    response: any;
    refetch: (url?: string) => void;
    loading: boolean;
    error: Error | null
} {
    const apiClient = useCustomApiClient();
    const [response, setResponse] = useState<any | null>(null);
    const [data, setData] = useState<T | null>(null);
    const [metadata, setMetadata] = useState<any | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<Error | null>(null);

    const fetchData = async (refetchUrl?:string) => {
        setLoading(true);
        setError(null);
        let apiUrl = url;
        if (refetchUrl) apiUrl=refetchUrl

        try {
            const responseData = await apiClient.fetch(apiUrl, 'GET',null,options);
            setResponse(responseData)
            const toJson = await responseData.json();
            if(!responseData.ok){
                setError(new Error("server error"));
            }
            setMetadata(toJson.meta)
            setData(toJson.data);
        } catch (error:any) {
            console.log(error)
            setError(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData(); // Automatically fetch data when the hook is used
    }, []);

    const refetch = (url?:string) => {
        fetchData(url);
    };

    return { data,metadata,response, loading, error, refetch };
}
export function useHttpPost(url: string, options: RequestOptions = {}): HttpPostHookReturnType {
    const apiClient = useCustomApiClient();



    const [response, setResponse] = useState<any | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<Error | null>(null);

    const postData = async (body: any, customUrl?: string) => {
        setLoading(true);
        setError(null);

        try {
            const apiUrl = customUrl || url || ''; // Use customUrl if provided, then url, then empty string
            const responseData = await apiClient.fetch(apiUrl, 'POST', body,options);
            console.log(responseData);
            setResponse(responseData);
            return responseData;
        } catch (error:any) {
            setError(error);
            throw new Error(error);

        } finally {
            setLoading(false);
        }
    };

    const resetData = () => {
        setResponse(null);
    };

    return [postData,{ response, loading, error , resetData }];
}
export function useHttpPut(baseUrl: string, options: RequestOptions = {}): HttpPutHookReturnType {
    const apiClient = useCustomApiClient();

    const [response, setResponse] = useState<any | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<Error | null>(null);

    const putData = async (id: string, body: any) => {
        setLoading(true);
        setError(null);

        try {
            const url = `${baseUrl}/${id}`;
            const responseData = await apiClient.fetch(url, 'PUT', body,options);
            setResponse(responseData);
            return responseData
        } catch (error: any) {
            setError(error);
        } finally {
            setLoading(false);
        }
    };

    const resetData = () => {
        setResponse(null);
    };

    return [putData, { response: response, loading, error, resetData }];
}
export function useHttpPatch(baseUrl: string, options: RequestOptions = {}): HttpPatchHookReturnType {
    const apiClient = useCustomApiClient();

    const [response, setResponse] = useState<any | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<Error | null>(null);

    const patchData = async (id: string, body: any) => {
        setLoading(true);
        setError(null);

        try {
            const url = `${baseUrl}${id}/`;
            const responseData = await apiClient.fetch(url, 'PATCH', body,options);
            setResponse(responseData);
            return responseData;
        } catch (error: any) {
            setError(error);
            throw new Error("Can't patch");
        } finally {
            setLoading(false);
        }
    };

    const resetData = () => {
        setResponse(null);
    };

    return [patchData, { response, loading, error, resetData }];
}
export function useHttpDelete(baseUrl: string, options: RequestOptions = {}): HttpDeleteHookReturnType {
    const apiClient = useCustomApiClient();

    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<Error | null>(null);

    const deleteData = async (id: string) => {
        setLoading(true);
        setError(null);

        try {
            const url = `${baseUrl}/${id}`;
            const response = await apiClient.fetch(url, 'DELETE',null,options);
            return response
        } catch (error: any) {
            setError(error);
        } finally {
            setLoading(false);
        }
    };

    return [deleteData, { loading, error }];
}
export function useLogin<T>(loginEndpoint: string, options: RequestOptions = {}): LoginReturnType<T> {
    const apiClient = useCustomApiClient();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [isConnected, setIsConnected] = useState(false);
    const [userData, setUserData] = useState<T | null>(null);

    useEffect(()=>{
        setLoading(true);
        //console.log(localStorage.getItem("connected"));
        const isConnected = localStorage.getItem("connected");
        const userData = localStorage.getItem("user");
        if (isConnected){
            JSON.parse(isConnected) ? setIsConnected(true) : setIsConnected(false)
            if(userData){
                setUserData(JSON.parse(userData))
            }
        }else{
            setIsConnected(false);
        }
        setLoading(false)

    },[loading])

    // useEffect(()=>{
    //
    //     //console.log(localStorage.getItem("connected"));
    //     const isConnected = localStorage.getItem("connected");
    //     if (isConnected){
    //         JSON.parse(isConnected) ? setIsConnected(true) : setIsConnected(false)
    //     }else{
    //         setIsConnected(false);
    //     }
    //
    // },[])



    const login = async (userCredential:UserCredential) => {
        localStorage.clear();
        setLoading(true);
        setIsConnected(false);
        setUserData(null);
        try {
            const response = await apiClient.fetch(loginEndpoint, 'POST', userCredential,options);
            const data = await response.json()
            if (data.data.token) {
                const userData = { ...data.data.user, token: data.data.token };
                delete userData.access;
                localStorage.setItem(
                    'user',
                    JSON.stringify(userData)
                );

                localStorage.setItem(
                    "connected",JSON.stringify(true)
                );

                // setLoading(false);
                setIsConnected(true);


                setUserData(userData); // Set user data if needed
            }else {
                setIsConnected(false);
                setUserData(null);
            }
            return data;
        } catch (e:any) {
            console.log(e)
            if (e.message === 'Network Error') {
                setError('Network error occurred.');
            } else {
                setError('Invalid credentials. Please try again.'); // Customize the error message as needed
            }
            throw new Error(error);
        }
        finally {
            setLoading(false);
        }
    };

    return [login,{ loading, error, isConnected, userData }];
}

export function useLogout(): LogoutReturnType {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [isLoggedOut, setIsLoggedOut] = useState(false);

    const logout = async () => {
        setLoading(true);
        try {
            // Clear token and user data
            localStorage.removeItem('user');
            localStorage.removeItem('connected');
            setIsLoggedOut(true);
        } catch (e) {
            console.log(e);
            setError('Error occurred during logout.'); // Customize the error message as needed
            throw new Error(error);
        } finally {
            setLoading(false);
        }
    };

    return [logout, { loading, error, isLoggedOut }];
}






