import React from 'react';
import {HorizontalNavBarProps} from "../../types";
import {FaShoppingBag} from "react-icons/fa";
import {medium} from "../../styles/icons";
import SpinningImage from "../SpinningImage";
import Image from "../Image";
import aetLogo from "../../assets/images/Logo-aet.png";
import Button from "../Button";
import {Link} from "react-router-dom";
import AETUserBox from "../../content/Users/AETUserBox";



const HorizontalNavBar: React.FC<HorizontalNavBarProps> = ({ menuItems }) => {
    const scrollToSection = () => {
        const section = document.getElementById('new-aet');
        if (section) {
            section.scrollIntoView({ behavior: 'smooth' });
        }
    };
    return (
        <nav className="horizontal-nav">

            <ul className="horizontal-nav-menu-item-list">
                <li className="logo">
                    <>
                        <Image src={aetLogo} alt={"Logo aet"} style={{width: "72px"}}/>
                    </>
                </li>
                {menuItems.map((menuItem, index) => (
                    <li key={index}>
                        <Link to={menuItem.url} className="menu-item">{menuItem.title}</Link>
                    </li>
                ))}
                <AETUserBox/>
            </ul>
        </nav>
    );
};

export default HorizontalNavBar;
