import React, {useEffect, useState} from 'react';
import { Navigate } from 'react-router-dom';
import {useLogin, useLogout} from "../../services/api/hooks/httpHooks";
import {useCustomApiClient} from "../../services/api/CustomApiClientProvider";
import {LocalStoredUser} from "../../types/api";
import toast from "react-hot-toast";

interface ProtectedRouteProps {
    children: React.ReactNode,
    admin:boolean,
    guest?:boolean,
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children,admin,guest }) => {
    const customApiClient = useCustomApiClient();
    const [logout, { loading:logoutLoading, error:logoutError, isLoggedOut }] = useLogout();
    const [tokenLoading , setTokenLoading] = useState(true);
    const [login, { isConnected,loading ,userData }] = useLogin<LocalStoredUser>('auth/login');
    const [redirectUrl,setRedirectUrl]= useState('/auth/login')
    useEffect(() => {
        if(admin) setRedirectUrl('/auth/admin/login')
    }, [admin]);

    useEffect(() => {
        // Example of setting a token on component mount
        setTokenLoading(false);
        if(userData){
            customApiClient.setJwtToken(userData.token);
            setTokenLoading(true);
        }
    }, [customApiClient, isConnected, userData]);

    const logoutAndGoToLogin = async ()=>{
        await logout();
        return  <Navigate to='/home'  replace/>;
    }

    if (!loading) {
        if (isConnected || guest) {
            if(admin){
                if(userData && userData.roles && !userData.roles.some(role => ['admin', 'super-admin'].includes(role.name))){
                    console.log('rendered login')
                    return  <Navigate to='/home'  replace/>;
                }else{
                    return <>{children}</>;
                }
            }
            else{
                return <>{children}</>;
            }
        } else {
            return <Navigate to={redirectUrl} replace />;
        }
    }

    return null;
};

export default ProtectedRoute;
