import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {CustomApiClient, CustomApiClientProvider} from "./services/api/CustomApiClientProvider";
import {API_BASE_URL} from "./config";
import Modal from "react-modal";

const customClient = new CustomApiClient(API_BASE_URL);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

Modal.setAppElement('#root');

root.render(
  <React.StrictMode>
      <CustomApiClientProvider value={customClient}>
          {/*<BrowserRouter>*/}
              {/*<ScrollToTop />*/}
              <App />
          {/*</BrowserRouter>*/}
      </CustomApiClientProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
