import {ThreeDots} from "react-loader-spinner";

function SuspenseLoader() {
    return (
        <ThreeDots
            visible={true}
            color="#4fa94d"
            radius="9"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClass=""
        />
    );
}

export default SuspenseLoader;
